.btn {
  font-family: 'IBMPlexSans-Medium' !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 0px 13px;
  background-color: #3DCD58 !important;
  color: #FFFFFF !important;
}

.btn.btn_sm {
  border-radius: 15px;
  height: 30px;
  font-size: 12px;
}

.btn.btn_delete {
  background-color: #B10043 !important;
  color: #FFFFFF !important;
}

.btn.btn_light_green {
  background-color: rgba(61, 205, 88, 0.2) !important;
  color: #3DCD58 !important;
}

.btn.btn_light_red {
  background-color: rgba(177, 0, 67, 0.07) !important;
  color: #B10043 !important;
}

.btn.btn_light_orange {
  background-color: #FCEFDE !important;
  color: #E47F00 !important;
}

.btn.btn_transparent {
  background-color: transparent !important;
  color: #9FA0A4 !important;
}

.btn.btn_light-blue {
  background-color: rgba(66, 180, 230, 0.1) !important;
  color: #42B4E6 !important;
}

.btn.btn_orange {
  background-color: #E47F00 !important;
  color: #FFFFFF !important;
}

.btn.normal-fonts {
  text-transform: none;
}

.btn.btn-with-icon {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.btn.btn-with-icon .icon,
.btn.btn-with-icon .text {
  margin: 0px 6px;
}

.btn.btn_full-space {
  border-radius: 0px;
  width: 100%;
}

button:disabled,
.btn:disabled {
  background-color: rgba(159, 160, 164, 0.5) !important;
  cursor: not-allowed !important;
  color: #FFFFFF !important;
}

.btn.btn_transparent:disabled {
  background-color: transparent !important;
  color: #9FA0A4 !important;
  cursor: not-allowed !important;
}

.btn.progress-overlay {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.btn.progress-overlay .progress {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: -1;
}

.btn.progress-overlay .progress_orange {
  background-color: #E47F00;
}
