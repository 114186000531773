.badge {
  background: #949494;
  color: #fff;
  padding: 1px;
  border-radius: 50%;
  font-size: 10px;
  width: 15px;
  height: 16px;
  display: inline-block;
  text-align: center;
  margin-left: 7px;
}

.require-field {
  position: relative;
}

.require-field::after {
  position: absolute;
  display: inline-block;
  content: '*';
  font-size: 14px;
  color: #B10043;
  right: -10px;
  top: 0px;
}

.transition-all {
  transition: all 0.3s;
}

.gray-actions {
  transition: 0.3s all;
  filter: grayscale(100);
  cursor: pointer;

  &:hover {
    filter: grayscale(0);
  }
}
