.dialog {
  position: fixed;
  background-color: #62646935;
  height: calc(100% - 102px);
  width: calc(100% - 180px);
  z-index: 2000;
  top: 102px;
  right: 0px;
  overflow: scroll;
}

.dialog .dialog-content {
  width: 920px;
  margin: 10px auto;
  background-color: #ffffff;
  box-shadow: -3px 2px 4px 0 rgba(0, 39, 69, 0.1);
}

.dialog .dialog-content.dialog-content_md {
  width: 720px
}

.dialog .dialog-content.dialog-content_sm {
  width: 420px
}

.dialog .dialog-content.dialog-content_lg {
  width: 1400px;
  max-width: 95%;
}

.dialog .dialog-header {
  background-color: #000000;
  color: #ffffff;
  padding: 14px 20px;
}

.dialog .dialog-header .close-icon {
  cursor: pointer;
}

.dialog .dialog-body {
  overflow: visible;
  min-height: calc(100% - 202px);
  color: #333333;
}

.dialog .dialog-actions .btn {
  color: #ffffff;
}
