.list-search-box {
  background-color: #FFFFFF;
  border: 1px solid rgba(159, 160, 164, 0.2);
  border-radius: 2px;
  padding: 10px;
  width: 100%;
}

.list-search-box .input {
  font-size: 12px !important;
  text-align: left !important;
  width: 100% !important;
  border: none !important;
  background-color: transparent !important;
}

.theme-date-time-picker-field {
  display: flex;
  border-bottom: 1px solid #D6E0E6;
  position: relative;
  min-height: 31px;
}

.theme-date-time-picker-field .rdtPicker,
.theme-event-date-time-picker-field .rdtPicker {
  top: 35px;
}


.theme-date-time-picker-field::after,
.theme-event-date-time-picker-field::after {
  position: absolute;
  right: 0px;
  bottom: 0px;
  content: url('/assets/images/icons/Date-In-text.svg');
  display: inline-block;
}

.theme-date-time-picker-field input {
  padding: 0px;
  padding-bottom: 10px;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 0px;
}

.theme-event-date-time-picker-field {
  position: relative;
  background-color: #FAFAFA;
  border-radius: 2px;
  height: 30px;
  display: flex;
  align-items: center;
}

.theme-event-date-time-picker-field::after {
  right: 5px;
}

.theme-event-date-time-picker-field input {
  padding: 0px 10px;
  width: calc(100% - 20px);
  background-color: transparent;
  font-family: 'IBMPlexSans-SemiBold';
  font-size: 12px;
}

.theme-event-date-time-picker-field input::placeholder {
  font-family: 'IBMPlexSans';
  font-style: italic;
}

.gray-bg-input {
  background-color: #f0f1f3;
  padding: 12px 8px;
  width: 100%;
}

.gray-bg-input.view-disabled {
  background-color: #D8D8D8;
  cursor: not-allowed;
}

.gray-bg-input_sm {
  padding: 8px 6px;
  font-size: 12px;
  font-family: 'IBMPlexSans';
}
