.custom-tabs {
  background-color: transparent;

  &-item {
    padding: 12px 15px;
    color: #9fa0a4;
    font-size: 14px;
    font-family: 'IBMPlexSans-SemiBold';
    text-transform: none;
    min-width: auto;
    max-width: unset;

    &.selected {
      color: #3dcd58;
    }
  }
}

.cs-tabs {
  background-color: #f5f5f5;

  &-item {
    padding: 12px 15px;
    color: #9fa0a4;
    font-size: 14px;
    font-family: 'IBMPlexSans-SemiBold';
    text-transform: none;
    min-width: auto;

    &.selected {
      color: #0c0c0c;
    }
  }
}
.search-cs {
  border: 1px solid #eae6e6;
  font-size: 14px;
  color: #716c6c;
}
