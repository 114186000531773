.booking-wizard .booking-forms {
  background-color: #ffffff;
  padding: 20px;
  justify-content: space-between;
  position: relative;
}

.booking-wizard .booking-forms.overlap::before,
.booking-wizard .booking-forms.overlap::after {
  cursor: not-allowed;
  content: " ";
  z-index: 5000;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  background-color: transparent;
}

.booking-wizard .booking-forms .stepper {
  padding-left: 15px;
  margin-top: 20px;
}

.booking-wizard .booking-forms .stepper .step {
  padding-left: 25px;
  border-left: 1px dashed #adbfcd;
  position: relative;
}

.booking-wizard .booking-forms .stepper .step:last-child {
  border-left: none;
}

.booking-wizard .booking-forms .stepper .step .step-header {
  height: 30px;
  display: flex;
  align-items: center;
}

.booking-wizard .booking-forms .stepper .step .step-content {
  padding: 10px 0px;
}

.booking-wizard .booking-forms .stepper .step .step-icon {
  position: absolute;
  left: -15px;
  top: 0px;
  height: 30px;
  width: 30px;
  background-color: #ececec;
  border-radius: 50%;
  text-align: center;
}

.booking-wizard .booking-forms .field-control {
  padding: 13px 0px;
}

.booking-wizard .booking-forms .field-control:first-child {
  padding-top: 0px;
}

.booking-wizard .booking-forms .field-control:last-child {
  padding-bottom: 0px;
}

.booking-wizard .booking-forms .field-control button:disabled {
  background-color: #e1e5ed !important;
  color: #adbfcd !important;
  font-size: 14px;
  line-height: 17px;
}

.booking-wizard .booking-forms .field-control .btn__submit {
  background-color: var(--bg-green) !important;
  color: #ffffff !important;
  text-align: center;
  height: 47px;
  padding: 0px;
  border-radius: 5px;
  text-transform: uppercase;
}

.booking-wizard .booking-forms .field-control .btn__back {
  background-color: transparent !important;
  color: #adbfcd !important;
  min-width: auto;
  text-transform: uppercase;
  margin-right: -8px;
}

.booking-wizard .booking-forms .field-control .btn__clear {
  background-color: transparent !important;
  text-transform: none;
  padding: 0px;
  min-width: auto;
  color: #9fa0a4 !important;
  font-size: 14px;
}

.booking-wizard .booking-forms .field-control .theme-radio,
.booking-wizard .booking-forms .field-control .theme-checkbox {
  color: var(--bg-green);
}

.booking-wizard .booking-forms .field-control .theme-text-field input {
  font-size: 14px;
}

.booking-wizard .booking-forms .field-control .field-label {
  font-size: 16px;
  color: #3d4852;
  font-weight: bold;
}

.booking-wizard .booking-forms .field-control .field-sub-label {
  font-size: 14px;
  font-weight: bold;
  color: #8795a1;
}

.booking-wizard .booking-forms .field-control .field-sub-label .image {
  min-width: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 12px;
}

.booking-wizard .booking-forms .field-control .field-instruction {
  font-weight: lighter;
  font-size: 12px;
  color: #b8c2cc;
}

.booking-wizard .booking-forms .field-control .field-action {
  font-weight: lighter;
  font-size: 12px;
  color: #b10043;
}

.booking-wizard .booking-forms .field-control .option-button {
  padding: 10px 15px;
  border: 1px solid #e1e5ed !important;
  color: #9fa0a4 !important;
  text-transform: none;
  flex: 1 auto;
  margin: 0px 5px;
}

.booking-wizard .booking-forms .field-control .option-button:first-child {
  margin-left: 0px;
}

.booking-wizard .booking-forms .field-control .option-button:last-child {
  margin-right: 0px;
}

.booking-wizard .booking-forms .field-control .option-button.active {
  border: 1px solid var(--bg-green) !important;
  background-color: rgba(24, 171, 118, 0.08) !important;
  color: var(--fg-green) !important;
}

.booking-wizard .booking-forms .field-control .search__laneId input {
  font-size: 14px;
  max-height: 42px;
}

.booking-wizard .booking-forms .field-control .lane-details {
  font-size: 12px;
  background-color: rgba(255, 170, 62, 0.05);
  border-radius: 4px;
  min-height: 462px;
  color: #8795a1;
  padding: 20px;
  overflow: hidden;
  max-width: 570px;
}

.booking-wizard .booking-forms .field-control .lane-details .lane-detail__instruction {
  max-width: 377px;
  text-align: center;
}

.booking-wizard .booking-forms .field-control .lane-details.not-selected {
  background-color: #fafafd;
}

.booking-wizard .booking-forms .field-control .lane-details .lane-details-container {
  padding: 35px 0px;
}

.booking-wizard .booking-forms .field-control .lane-details .lane-details-container:first-child {
  padding-top: 0px;
}

.booking-wizard .booking-forms .field-control .lane-details .lane-details-container:last-child {
  padding-bottom: 0px;
}

.booking-wizard .booking-forms .field-control .lane-details .header {
  color: #ffaa3e;
}

.booking-wizard .booking-forms .field-control .lane-details .label {
  color: #8795a1;
}

.booking-wizard .booking-forms .field-control .lane-details .value {
  color: #424d62;
  text-align: right;
}

.booking-wizard .booking-forms .field-control .mark-spot__reason textarea {
  font-size: 12px;
}

.booking-wizard .booking-forms .field-control .mark-spot__instruction {
  color: #cdd2e1;
  margin-top: 10px;
  font-size: 10px;
}

.booking-wizard .booking-summary {
  height: 100%;
  padding: 20px;
  background-color: #fafafd;
}

.booking-wizard .booking-summary .info-container {
  padding: 20px 0px;
  border-top: 1px dashed #e1e5ed;
}

.booking-wizard .booking-summary .info-container:first-child {
  padding-top: 0px;
  border-top: none;
}

.booking-wizard .booking-summary .info-container:last-child {
  padding-bottom: 0px;
}

.booking-wizard .booking-summary .info-container .header {
  color: #4d5d7e;
  font-size: 16px;
}

.booking-wizard .booking-summary .info-container .label {
  color: #9fa0a4;
  font-size: 12px;
}

.booking-wizard .booking-summary .info-container .value {
  color: #424d62;
  text-align: right;
  font-size: 12px;
}

.booking-wizard .booking-summary .info-container .instruction {
  color: #9fa0a4;
  font-size: 12px;
}

.booking-wizard .booking-summary .info-container .btn__freeze {
  background-color: transparent !important;
  text-transform: none;
  padding: 0px;
  min-width: auto;
  color: #ff5353 !important;
  font-size: 14px;
}
