.line-connector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 28px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.line-connector .line {
    position: relative;
    height: 0px;
    width: 100%;
    border: 0.5px solid #3DCD58;
    display: flex;
    align-items: center;
    justify-content: center;
}

.line-connector .line .start,
.line-connector .line .end {
    position: absolute;
    top: -14px;
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    background-color: #3DCD58;
    overflow: hidden;
}

.line-connector .line .start.no-bg,
.line-connector .line .end.no-bg {
    background-color: transparent;
}

.line-connector .line .start .icon,
.line-connector .line .end .icon {
    height: inherit;
    width: inherit;
}

.line-connector .line .start {
    left: -1px;
}

.line-connector .line .start.no-bg {
    left: -3px;
} 

.line-connector .line .end {
    right: 0px;
}

.line-connector .line .end.no-bg {
    right: -3px;
} 

.line-connector .line .label {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 10px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 3px 8px;
    background-color: #3DCD58;
    border-radius: 2px;
}

.line-connector .line.dashed {
    border-style: dashed;
}

.line-connector .line.secondary {
    border-color: #42B4E6;
}

.line-connector .line.secondary .start,
.line-connector .line.secondary .label,
.line-connector .line.secondary .end {
    background-color: #42B4E6;
}