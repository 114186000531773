.shipment-details {
  overflow: auto;

  &-container {
    background-color: #ffffff;
    margin-bottom: 20px;
    overflow: auto;

    &-section {
      padding: 20px;
      border-bottom: 1px dashed #ebebeb;

      &:last-child {
        border-bottom: none;
      }

      .section__title {
        font-family: "IBMPlexSans";
        font-size: 14px;
        color: #9fa0a4;
        display: flex;
        align-items: center;

        &.bold {
          font-family: "IBMPlexSans-Bold";
        }

        &.dark {
          color: #333333;
        }
      }

      .section__info {
        font-family: "IBMPlexSans";
        font-size: 12px;
        color: #9fa0a4;
        font-style: italic;
      }

      .shipment-events {
        font-size: 12px;
        font-family: "IBMPlexSans";

        &-item {
          position: relative;
          margin-left: 4px;
          padding-left: 14px;
          border-left: 1px dashed #ebebeb;
          min-height: 50px;

          &.big_dots {
            &::before {
              width: 30px;
              height: 30px;
              top: 0px;
              left: -15px;
            }

            &:last-child {
              &::before {
                left: -15px;
              }
            }
          }

          &::before {
            content: " ";
            position: absolute;
            display: inline-block;
            background-color: #9fa0a4;
            border-radius: 100%;
            width: 7px;
            height: 7px;
            top: 0px;
            left: -4px;
          }

          &.completed {
            border-color: #3dcd58;

            &::before {
              background-color: #3dcd58;
            }

            &::after {
              content: " ";
              position: absolute;
              display: inline-block;
              border-radius: 100%;
              width: 15px;
              height: 15px;
              border: 4px solid rgba(61, 205, 88, 0.2);
              top: -4px;
              left: -8px;
            }

            .shipment-events-name {
              color: #7f7f7f;
            }
          }

          &:last-child {
            border-left: none;
            min-height: 30px;

            &::before {
              left: -3.5px;
            }

            &::after {
              left: -7.5px;
            }
          }

          &-container {
            position: relative;
            top: -4px;
          }
        }

        &-name {
          font-family: "IBMPlexSans-SemiBold";
        }

        &-value {
          .today-picker {
            font-family: "IBMPlexSans-SemiBold";
            color: #3dcd58;
            font-size: 10px;
            border-bottom: 1px dashed #3dcd58;
            text-transform: uppercase;
            cursor: pointer;
          }

          .no-display {
            display: none;
          }

          .value-field-input {
            background-color: #fafafa;
            border-radius: 2px;
            width: 100%;

            &>div::before {
              display: none;
            }

            &>div::after {
              display: none;
            }

            input {
              font-family: "IBMPlexSans-SemiBold";
              font-size: 12px;
              color: #333333;
              padding: 8px 10px;

              &::placeholder {
                font-family: "IBMPlexSans";
                font-style: italic;
              }
            }
          }

          .action-icons {
            transition: all 0.3s;
            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            opacity: 0.5;

            &.save.active,
            &.edit:hover {
              -webkit-filter: grayscale(0%);
              /* Safari 6.0 - 9.0 */
              filter: grayscale(0%);
              cursor: pointer;
              opacity: 1;
            }
          }
        }
      }

      .event-tracking {
        &-item {
          position: relative;
          padding-left: 14px;
          border-left: 1px dashed #3dcd58;
          min-height: 40px;

          &.last {
            border-left: 1px dashed transparent;
          }

          &::before {
            position: absolute;
            display: inline-block;
            top: -4px;
            left: -15px;
          }

          &.check-list {
            &::before {
              content: url('/assets/images/icons/events/event-scheduling.svg');
            }
          }

          &.transporter {
            &::before {
              content: url('/assets/images/icons/events/event-transporter.svg');
            }
          }

          &.customs {
            &::before {
              content: url('/assets/images/icons/events/event-customs.svg');
            }
          }

          &.ship {
            &::before {
              content: url('/assets/images/icons/events/event-ship.svg');
            }
          }

          &.completed {
            border-color: #3dcd58;

            .shipment-events-name {
              color: #7f7f7f;
            }
          }

          &:last-child {
            min-height: 50px;

            &::after {
              left: -7.5px;
            }
          }

          &-container {
            position: absolute;
            top: -11px;
          }
        }

        .scheduling {
          &-title {
            font-family: "IBMPlexSans-Bold";
            font-size: 16px;
          }

          &-info {
            font-size: 12px;
            font-family: "IBMPlexSans";
            color: #9fa0a4;
          }

          &-container {
            border-left: 1px dashed #3dcd58;
            position: relative;

            &.last {
              border-left: 1px dashed transparent;
            }
          }

          &-name:hover {
            &.cancelled {
              color: #3dcd58;
            }

            color: #3dcd58;
            text-decoration-line: underline;
            text-decoration-style: dashed;
            cursor: pointer;
          }

          &-name,
          &-value {
            font-family: "IBMPlexSans-Medium";
            font-size: 12px;
          }

          &-name {
            color: #9fa0a4;

            &.cancelled {
              color: #B10043;
            }
          }
        }
      }

      .booking-details {
        &-item {
          padding-right: 40px;

          &:last-child {
            padding-right: 0px;
          }
        }

        &-name,
        &-value {
          font-size: 12px;
          margin-bottom: 3px;
        }

        &-name {
          font-family: "IBMPlexSans";
          color: #9fa0a4;
        }

        &-value {
          font-family: "IBMPlexSans-SemiBold";
          padding-right: 12px;
          color: #333333;

          a.green-underline {
            color: #3dcd58;
            text-decoration-line: underline;
            text-decoration-style: dashed;
            cursor: pointer;
          }

          a.green-underline:hover {
            color: #3dcd58;
            text-decoration-line: underline;
            text-decoration-style: dashed;
            cursor: pointer;
          }
        }
      }

      .documents-list {
        font-size: 12px;
        border: 0px !important;

        .table-head {
          border: 0px !important;

          .table-row {
            border: 0px !important;

            .table-cell:first-child {
              width: 10px;
            }

            .table-cell {
              border: 0px !important;
            }
          }
        }

        .table-body {
          border: 0px !important;

          .table-row {
            border: 0px !important;

            .table-cell:first-child {
              width: 10px;
            }

            .table-cell {
              border: 0px !important;
              font-weight: 800;
              color: #000;

              .view-all {
                color: #42b4e6;
                font-weight: 800;
              }

              .download-btn {
                color: #3dcd58;
                font-weight: 800;
              }

              .doc-thumbnail {
                height: 30px;
              }

              .badge {
                background: #949494;
                color: #fff;
                padding: 1px;
                border-radius: 50%;
                font-size: 10px;
                width: 15px;
                height: 16px;
                display: inline-block;
                text-align: center;
                margin-left: 7px;
              }

              .light {
                font-weight: 300;
                color: #7f7f7f;
              }
            }
          }
        }
      }
    }

    .shipment-route {
      margin-bottom: 20px;

      &-status {
        background-color: rgba(61, 205, 88, 0.06);
        position: relative;
        padding-left: 40px;
        border: 1px solid #ebebeb;

        &::before {
          position: absolute;
          display: inline-block;
          content: "";
          width: 7px;
          height: 7px;
          background-color: #3dcd58;
          border-radius: 100%;
          left: 20px;
          top: 20px;
        }

        &::after {
          position: absolute;
          display: inline-block;
          content: "";
          width: 15px;
          height: 15px;
          border: 4px solid rgba(61, 205, 88, 0.2);
          border-radius: 100%;
          left: 16px;
          top: 16px;
        }

        &-text {
          font-size: 12px;
          font-family: "IBMPlexSans-SemiBold";
          margin-bottom: 5px;
        }

        &-date-time {
          font-family: "IBMPlexSans";
          font-size: 10px;
          color: #9fa0a4;
        }
      }

      &-timeline {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
      }
    }
  }

  .pre-alert-log {
    .message-container {
      font-size: .8em;
      overflow: auto;

      table {
        width: 100%;
        tr th,
        tr td {
          border: 1px solid #9fa0a4;
          padding: 2px 5px;
          text-align: left;
        }
      }
    }
  }

  .mark-exception-button {
    background-color: #e47f00 !important;
  }

  .red-tag {
    background-color: #b10043;
    color: #fff;
  }

  .orange-tag {
    background-color: #e47f00;
    color: #fff;
  }

  .orange-tag-exception {
    background: rgba(228, 127, 0, 0.15000000596046448);
    color: #e47f00;
  }

  .green-tag {
    background-color: #3dcd58;
    color: #fff;
  }

  .exception-sidebar-header {
    background: #ececed;
    color: #9fa0a4;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    margin-left: -20px;
    margin-top: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
  }

  .exception-reason-selection-row-selected {
    outline: 1px solid #3dcd57 !important;
    background: #3dcd571c !important;
  }

  .exception-reason-selection-row {
    border-radius: 4px;
    background: #f5f5f5;
    padding: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    outline: 0.5px solid #d1d4d2 !important;

    &:hover {
      outline: 1px solid #3dcd57 !important;
      background: #3dcd571c;
    }

    .title {
      margin-bottom: 0.6em;
    }

    .sub-title {
      color: #b4b4b8;
    }
  }
}

.documents-sidebar-row {
  border-bottom: 1px dashed #7f7f7f;
  padding-bottom: 10px;
  padding-top: 5px;

  &:last-child {
    border-bottom: unset;
  }

  .title {
    max-width: 200px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-title {
    color: #b4b4b8;
    font-size: 12px;
  }

  .file-icon {
    cursor: pointer;
  }
}

.deleted-documents-sidebar-row {
  border-bottom: 1px dashed #7f7f7f;
  padding-bottom: 10px;
  padding-top: 5px;

  &:last-child {
    border-bottom: unset;
  }

  .title {
    max-width: 300px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-title {
    color: #b4b4b8;
    font-size: 12px;
  }

  .file-icon {
    cursor: pointer;
  }
}
