.notifications-sidebar-row {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 1em;
  margin-bottom: 1em;

  .title {
    margin-bottom: 0.6em;
    font-weight: 800;
  }
  .sub-title {
    color: #b4b4b8;
  }
  .datetime {
    margin: auto 2px;
    color: #b4b4b8;
    float: right;
    font-size: 10px;
  }

  .time-remaining {
    margin-top: 2px;
    float: left;
    color: #b4b4b8;
  }
}
.read-notification {
  opacity: 0.5;
}
