.users .filters .btn-filter {
  background-color: rgba(159, 160, 164, 0.1);
  border: 1px dashed rgba(159, 160, 164, 0.3);
  border-radius: 16px;
  color: #9fa0a4;
  font-size: 10px;
  height: 25px;
  margin: 0px 5px;
  font-family: 'IBMPlexSans-Bold';
  vertical-align: middle;
  padding: 0px 20px;
}

.users .filters .btn-filter.active {
  background-color: #e0f7e5;
  border: 1px solid #3dcd58;
  color: #3dcd58;
}

.users {
  padding: 20px;
}

.users .users-header {
  margin-bottom: 20px;
}

.users .users-header .search-box {
  background-color: #ffffff;
  border: 1px solid rgba(159, 160, 164, 0.2);
  border-radius: 2px;
  padding: 10px;
}

.users .users-header .search-box .input {
  font-size: 12px;
}

.users .users-header .total {
  color: #9fa0a4;
  text-align: right;
}

.users .users-list .logo {
  height: 40px;
  padding: 5px;
}

.users .users-list .actions {
  height: 20px;
  width: auto;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
}

.user-active {
  height: 10px;
  width: 10px;
  background-color: #b1ebbc;
  display: inline-block;
  border-radius: 50%;
  margin: auto 10px;
}

.user-invited {
  height: 10px;
  width: 10px;
  background-color: #b3e1f5;
  display: inline-block;
  border-radius: 50%;
  margin: auto 10px;
}

.user-inactive {
  height: 10px;
  width: 10px;
  background-color: #ffe5c5;
  display: inline-block;
  border-radius: 50%;
  margin: auto 10px;
}

.user-phone {
  color: #9fa0a4;
  font-weight: 400;
}
user-phone ::before {
  content: "Read this: ";
}