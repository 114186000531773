@import 'normalize.css';

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: 'IBMPlexSans';
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
  color: #333333;
  font-family: 'IBMPlexSans';
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

ul,
li {
  font-size: 14px !important;
  font-family: 'IBMPlexSans' !important;
  color: #333333;
}

.MuiSelect-icon{
  top: calc(50% - 8px);
  right: 10px;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  *::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  *:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.37);
    border-radius: 10px;
  }
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

@import 'tailwind.css';

@import 'main.scss';

@import 'scss/cores/page.css';
@import 'scss/cores/colors.css';
@import 'scss/cores/typography.css';
@import 'scss/cores/buttons.css';
@import 'scss/cores/side-bar.css';
@import 'scss/cores/dialog.css';
@import 'scss/cores/tables.css';
@import 'scss/cores/inputs.css';
@import 'scss/cores/drawable.css';
@import 'scss/cores/filters.css';
@import 'scss/cores/forms.css';
@import 'scss/cores/custom-tabs.scss';
@import 'scss/cores/wizard.css';
@import 'scss/cores/notifications-sidebar.scss';
@import 'scss/cores/date-time-picker.css';
@import 'scss/cores/utils.scss';
@import 'scss/cores/ql-editor.scss';

@import 'scss/pages/login.css';
@import 'scss/pages/welcome.scss';
@import 'scss/pages/my-network.css';
@import 'scss/pages/forms.css';
@import 'scss/pages/sidebar.css';
@import 'scss/cores/page.css';
@import 'scss/pages/organizations.css';
@import 'scss/pages/offices.css';
@import 'scss/pages/rates.css';
@import 'scss/pages/benchmarks.css';
@import 'scss/pages/map-view.css';
@import 'scss/pages/bookings.css';
@import 'scss/pages/shipment.scss';
@import 'scss/pages/shipment-list.scss';
@import 'scss/pages/shipment-details.scss';
@import 'scss/pages/users.css';
