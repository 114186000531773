#fuse-navbar > div {
  background: white !important;
}

.document-upload {
  border: 1px dashed rgba(156, 167, 196, 0.6);
  border-radius: 4px;
  height: 616px;
}

.welcome__header {
  color: #002745;
  font-family: 'IBMPlexSans-SemiBold';
  font-size: 18px;
  line-height: 22px;
}

.welocme__instructions {
  color: #9ca7c4;
  font-family: 'IBMPlexSans';
  font-size: 14px;
  line-height: 20px;
}

.btn-green {
  background-color: #10b87b !important;
  min-width: 167px;
  color: #ffffff;
  padding: 13px 30px;
  font-size: 12px;
  font-family: 'IBMPlexSans-Medium';
  line-height: 14px;
  font-weight: 500;
}

.btn-green_small {
  padding: 5px 20px;
  min-width: auto;
}

.btn-green_lg {
  width: 100%;
}

.upload-documents {
  position: fixed;
  top: 66px;
  right: 0px;
  z-index: 100;
  border-radius: 0;
}

.upload-documents__header {
  background-color: #050d34;
  padding-left: 20px;
  color: #ffffff;
  font-family: 'IBMPlexSans-Bold';
  font-size: 16px;
}

.documents {
  border-bottom: 1px dashed #9ca7c4;
}
.documents:last-child {
  border-bottom: none;
}

.documents__title {
  color: #424d62;
  font-family: 'IBMPlexSans-Bold';
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-bottom: 10px;
}

.documents__download {
  color: #2784d9;
  font-family: 'IBMPlexSans-SemiBold';
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.document__status {
  font-family: 'IBMPlexSans';
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  margin-bottom: 6px;
}

.document__status_last {
  margin-bottom: 0px;
}

.success {
  color: #10b87b;
}

.warning {
  color: #ffaa3e;
}

.error {
  color: #ea4b4b;
}

.documents__log {
  color: #9fa0a4;
}

.disabled {
  background: #dddddd !important;
  cursor: not-allowed !important;
}
/* filepicker */

/* Filepicker CSS */
.filepicker {
  font-family: sans-serif;
}

div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #eaeaea66;
  border-radius: 5px;
  min-height: 60px;
  border: 2px dashed #d6d6d6;
  display: flex;
  flex-direction: column;
  cursor: default !important;

  &.active {
    border-style: solid;
    border-color: #3dcd58;
    background: #ecfaee;
  }
  &.single {
    .dz-preview {
      display: none;

      &:last-child {
        display: flex;
      }
    }
  }

  .dz-message,
  .dz-image,
  .dz-progress,
  .dz-error-message,
  .dz-success-mark,
  .dz-error-mark {
    display: none !important;
  }
  .dz-preview {
    min-height: auto;
    height: 16px;
    margin: 4px 14px 4px 49px;
    background: transparent !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dz-details {
      position: relative;
      display: inline-flex;
      min-width: auto;
      max-width: 208px;
      padding: 0;
      flex-direction: row-reverse;
      opacity: 1;
      font-size: 11px;
      color: #626469;

      .dz-size {
        margin: 0;
        font-size: 11px;
        width: 70px;

        & > span > strong {
          font-weight: normal;
        }
      }
      .dz-filename {
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
          border: none !important;
          background: none !important;
        }
      }
    }
    .dz-remove {
      font-size: 11px;
      color: #b10043;
      width: 42px;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      flex: none;
    }
  }
  .dz-message.active {
    height: 1px;
    display: block !important;
    margin: 0 16px 10px 54px;
    border-top: 1px dashed grey;

    & > span {
      display: none;
    }
  }
}

/* Icon */
.filepicker-file-icon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;
  color: black;
}

.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;
  width: 29px;
  height: 34px;
  content: '';
  border: solid 2px #7f7f7f;
  border-radius: 2px;
}

.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;
  position: absolute;
  top: 9px;
  left: -4px;
  padding: 0 2px;
  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: #000;
}

.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;
  width: 0;
  height: 0;
  border-width: 11px 0 0 11px;
  border-style: solid;
  border-color: white transparent transparent #920035;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

/* dropzone */

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview.dz-image-preview.dz-error{
  background-color: rgba(248, 4, 4, 0.397) !important;
}

.dropzone .dz-preview.dz-image-preview.dz-error:hover .dz-error-message{
  display: block !important;
}

.dropzone .dz-preview:hover.dz-error .dz-error-message,
.dropzone-previews .dz-preview:hover.dz-error .dz-error-message {
  display: block;
}

.drop-files {
  font-size: 14px;
  font-family: 'airbnb_cereal_appmedium' !important;
  font-weight: 500;
  color: #9fa0a4;
}

.w-100 {
  width: 100px;
}

.highlight-cell {
  border: 1px solid #e47f00 !important;
  background: #fcf2e5;
}

.summary--title {
  color: #333333;
  font-family: 'IBMPlexSans-SemiBold';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.summary--subtitle {
  color: #9fa0a4;
  font-family: 'IBMPlexSans-Medium';
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.summary__details {
  color: #626469;
  font-family: 'IBMPlexSans';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.border--green {
  border: 1px dashed rgba(61, 205, 88, 0.4) !important;
}

#fuse-toolbar {
  background: white;
  border: 1px solid #ebf0f7;
  box-shadow: none !important;
}

.navigation {
  font-family: 'IBMPlexSans-Medium';
  font-weight: 500;
}

.navigation li {
  color: #9fa0a4 !important;
  font-size: 12px;
  line-height: 14px;
}

.navigation a.list-item,
.navigation a.list-item > span,
.navigation a.list-item > div.list-item-text > span {
  color: #7f7f7f !important;
  font-size: 14px;
  line-height: 17px;
}

.navigation .list-item.active {
  position: relative;
}

.navigation .list-item.active::before {
  content: ' ';
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 4px;
  background-color: #009530;
}

.navigation .list-item.active,
.navigation .list-item.active > span,
.navigation .list-item.active > div.list-item-text > span {
  background: none !important;
  color: #009530 !important;
}

#app {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.map {
  height: 330px;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.back-home {
  color: #2784D9 !important;
}
