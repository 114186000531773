
/* START Basic form inputs */
.formsy-field-row {
    margin-bottom: 40px;
}
.formsy-field-row.last {
    margin-bottom: 0px;
}

.formsy-field-column {
    padding: 0px 10px;
}

.formsy-field-column.first {
    padding-left: 0px;
}

.formsy-field-column.last {
    padding-right: 0px;
}

.formsy-form-control div::before,
.formsy-form-control div::after {
    display: none;
}

.formsy-form-control input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: auto !important;
    font-size: inherit !important;
    color: #424D62 !important;
}

.formsy-form-control__label {
    color: #8795a1;
    font-family: 'IBMPlexSans';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    display: inline-block;
}

.formsy-form-control__sub-label {
    display: inline-block;
    font-size: 14px;
    font-family: 'ibm_plex_sans_lightregular';
    color: #ADBFCD;
    margin-bottom: 15px;
}

.formsy-input,
.formsy-select {
    width: 100%;
    border-bottom: 1px solid #D6E0E6;
    /* border-radius: 5px; */
    font-size: 14px;
    /* padding: 5px 10px; */
    color: #424D62;
}

.formsy-input div,
.formsy-select div {
    color: inherit;
    font-size: inherit;
}

/* END Basic form inputs */


/* START theme field styles */
.theme-outlined {
    background-color: #FAFAFD !important;
    border: 1px solid #E1E5ED !important;
    border-radius: 3px !important;
}

.theme-outlined input {
    padding-left: 15px !important;
    font-family: 'IBMPlexSans';
}

.theme-outlined input::placeholder,
.theme-outlined input:-ms-input-placeholder,
.theme-outlined input::-ms-input-placeholder,
.theme-outlined textarea::placeholder,
.theme-outlined textarea:-ms-input-placeholder,
.theme-outlined textarea::-ms-input-placeholder {
    color: #ADBFCD;
}

.theme-outlined fieldset {
    height: 0px !important;
}

.theme-text-field>div {
    margin: 0px;
    margin-top: 5px;
}

.theme-text-field>div::before {
    border-color: #D6E0E6;
}

.theme-text-field label {
    font-family: 'IBMPlexSans';
    color: #9FA0A4 !important;
    font-weight: normal;
}

.theme-text-field input {
  height: 45px !important;
  padding: 0px !important;
  width: 100%;
  font-family: 'IBMPlexSans';
  font-size: 14px;
}

.theme-text-field textarea {
    min-height: 20px !important;
    margin-top: 10px !important;
    padding: 0px !important;
}

/* END theme field styles */




.group-header{
    color: #002745;
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 12px;
}

.field-item{
    margin-bottom: 12px;
}

.theme-text-field>div {
    /* margin: 0px;
    margin-top: 5px; */
}

.theme-text-field>div::before {
    border-color: #D6E0E6;
}

.theme-text-field label {
    color: #8795a1 !important;
}

.theme-text-field.required label::after {
  position: absolute;
  display: inline-block;
  content: '*';
  font-size: 14px;
  color: #B10043;
  right: -10px;
  top: 0px;
}

.theme-text-field textarea {
    min-height: 20px !important;
    margin-top: 10px !important;
    padding: 0px !important;
}

.theme-text-field.select_input_align{
    margin-top:2px !important;
}

.exception-label{
   color: #8795a1 !important
}

.theme-text-field-border {
    border-bottom: 1px solid rgba(156, 167, 196, 0.4) !important
}
