.side-dlg{
    font-size: 14px;
}

.side-dlg__header{

}

.side-dlg__header--subheader{
    background-color: #E1E5ED;
    padding: 12px 20px;
}

.side-dlg__title{
    color: #9FA0A4;
    font-family: 'IBMPlexSans-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.side-dlg__title--capital{
    text-transform: uppercase;
}

.side-dlg .formsy-form-control__label {
    font-size: 12px;
    color: #9FA0A4;
    font-family: 'IBMPlexSans';
    margin: 5px 0px;
}

.side-dlg .formsy-select,
.side-dlg .theme-text-field input {
    font-size: 14px;
}

.side-dlg .theme-text-field input {
    height: 26px !important;
}
