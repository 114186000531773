.my-network {
    padding: 20px;
}

.my-network__header {
    min-width: 120px;
}

.my-network-list-table {
    background-color: #FDFDFD;
    border: 1px solid #EBF0F7;
    border-radius: 4px;
}

.my-network-list-table .table-cell {
    padding: 20px !important;
}

.my-network-list-table .table-cell.table-cell_header {
    background-color: #EBF0F7;
}

.my-network-list-table .table-row th.cell-with-status,
.my-network-list-table .table-row td.cell-with-status {
    padding-left: 0px;
}

.my-network-list-table .table-row th.cell-with-status::before {
    content: " ";
    display: inline-block;
    height: 20px;
    width: 20px;
}

.my-network-list-table .table-row td.unlinked-status::before {
    content: " ";
    display: inline-block;
    height: 13px;
    width: 13px;
    margin: 0px 10px;
    border: 2px solid #FFAA3E;
    border-radius: 100%;
    background-color: rgba(255, 170, 62, 0.2);
}

.my-network-list-table .table-row td.linked-status::before {
    content: " ";
    display: inline-block;
    height: 13px;
    width: 13px;
    margin: 0px 10px;
    border: 2px solid #10B87B;
    border-radius: 100%;
    background-color: rgba(16, 184, 123, 0.2);
}

.my-network-list-table .table-row td,
.my-network-list-table .table-row th {
    padding: 10px 20px;
    border: 1px solid #E0E0E0;
    font-size: 14px;
}

.my-network-list-table .table-row th {
    color: #8795a1;
    font-weight: bold;
}

.my-network-list-table .table-row td {
    color: #424D62;
}

.my-network-list-table .table-row td.unlinked {
    background-color: rgba(255, 170, 62, 0.1);
}

.my-network-list-table .table-row td.linked span {
    border-bottom: 1px dashed #4D5477;
}

.my-network-list__instructor {
    font-family: 'IBMPlexSans-Medium';
    line-height: 17px;
}

.my-network-list__instructor.unlinked {
    color: #FFAA3E;
}

.my-network-list__instructor.linked {
    color: #10B87B;
}

.my-network-list__instructor::before {
    display: inline-block;
    content: ' ';
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.my-network-list__instructor.unlinked::before {
    border: 2px solid #FFAA3E;
    background-color: #FFEED8;

}

.my-network-list__instructor.linked::before {
    border: 2px solid #10B87B;
    background-color: #10b87b33;
}



.my-network-list-columns {
    background-color: #FFFFFF;
    border: 1px solid rgba(159, 160, 164, 0.3);
    border-radius: 2px;
    padding: 11px 14px;
    width: 100%;
    cursor: pointer;
}

.my-network-list-columns .hidden__count {
    color: #8795a1;
    font-size: 14px;
}

.my-network-list-column-list {
    position: absolute;
    top: 50px;
    background-color: #FFFFFF;
    border: 1px solid #EBF0F7;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(5, 13, 52, 0.1);
    width: 100%;
    z-index: 100;
    height: 325px;
}

.my-network-list-column-list ::-webkit-scrollbar {
    width: 2px;
}

.my-network .my-network-party-count {
    padding-bottom: 24px;
}

.my-network .my-network-party-count .card {
    background-color: #FFFFFF;
    border-radius: 2px;
    padding: 10px;
    width: 140px;
}

.my-network .my-network-party-count .card .unique {
    font-family: 'IBMPlexSans-Bold';
    font-size: 19px;
}

.my-network .my-network-party-count .card .total {
    font-size: 11px;
    color: #9FA0A4;
    text-align: right;
}

.my-network .my-network-party-count .card .title {
    font-family: 'IBMPlexSans-Medium';
    font-size: 9px;
    color: #9FA0A4;
    text-transform: uppercase;
}

/* React Data Grid */
.my-network .react-data-grid {
    font-family: 'IBMPlexSans';
    font-size: 14px;
}

.my-network .react-data-grid .react-grid-Container {
    border: 1px solid rgba(159, 160, 164, 0.2);
    border-radius: 4px;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Toolbar .tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Toolbar .tools .btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    font-size: 12px;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header {
    background-color: rgba(159, 160, 164, 0.08);
}

.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow .react-grid-HeaderCell,
.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Viewport .react-grid-Canvas .react-grid-Row .react-grid-Cell {
    height: 40px;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow .react-grid-HeaderCell {
    font-family: 'IBMPlexSans-Medium';
    color: #7F7F7F;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow .react-grid-HeaderCell .Select-control {
    width: 100%;
    overflow-x: scroll;
    display: block;
}

.my-network .react-data-grid .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Header .react-grid-HeaderRow .react-grid-HeaderCell .Select-control::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

/* React Data Grid */

.my-network .filters .btn-filter {
    background-color: rgba(159, 160, 164, 0.1);
    border: 1px dashed rgba(159, 160, 164, 0.3);
    border-radius: 16px;
    color: #9FA0A4;
    font-size: 10px;
    height: 25px;
    margin: 0px 5px;
    font-family: 'IBMPlexSans-Bold';
    vertical-align: middle;
    padding: 0px 20px;
}

.my-network .filters .btn-filter.active {
    background-color: #E0F7E5;
    border: 1px solid #3DCD58;
    color: #3DCD58;
}

.my-network .linked-partner {
    border-bottom: 1px dashed #333333;
}