.rates {
    padding: 20px;
}

.rates .rates-list .table-cell {
    vertical-align: top;
    padding: 25px !important;
}

.rates .rates-list .light-fonts {
    color: #9FA0A4;
}

.rates .rates-list .rate-descriptions {
    position: relative;
    padding: 10px;
    font-size: 12px;
    padding-left: 40px;
}

.rates .rates-list .rate-descriptions::before {
    position: absolute;
    display: inline-block;
    content: ' ';
    height: 20px;
    width: 20px;
    margin: 0px 10px;
    border-radius: 5px;
    left: 0px;
}

.rates .rates-list .rate-descriptions.cb::before {
    content: url('/assets/images/icons/rates/Doc-route-benchmark.png');
}

.rates .rates-list .rate-descriptions.lp::before {
    content: url('/assets/images/icons/rates/Truck-route-benchmark.png');
}

.rates .rates-list .rate-descriptions.fp::before {
    content: url('/assets/images/icons/rates/Ship-route-benchmark.png');
}

.rates .rates-list .rate-descriptions:first-child {
    padding-top: 50px;
}

.rates .rates-list .rate-descriptions .service-provider {
    font-family: 'IBMPlexSans-Medium';
    line-height: 20px;
}

.rates .rates-list .rate-descriptions .currency {
    font-family: 'IBMPlexSans-Medium';
}

.rates .rates-list .rate-descriptions .charge {
    padding: 3px 0px;
    font-family: 'IBMPlexSans';
}

.rates .rates-list .rate-descriptions .charge .type {
    max-width: 160px;
}

.rates .rates-list .rate-descriptions .charge .amount {
    font-family: 'IBMPlexSans-Medium';
    color: #333333;
}

.rates .rates-list .rate-descriptions .charge .count-by {
    padding-left: 3px;
}

.rates .rates-list .rate-descriptions .charge .amount::before {
    display: inline-block;
    margin-right: 4px;
}

.rates .rates-list .rate-descriptions .charge .amount.SGD::before {
    content: 'S$';
    color: #009530;
}

.rates .rates-list .rate-descriptions .charge .amount.EUR::before {
    content: '€';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.USD::before {
    content: '$';
    color: #009530;
}

.rates .rates-list .rate-descriptions .charge .amount.INR::before {
    content: '₹';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.IDR::before {
    content: 'Rp';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.MYR::before {
    content: 'RM';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.HKD::before {
    content: 'HK$';
    color: #009530;
}

.rates .rates-list .rate-descriptions .charge .amount.TWD::before {
    content: 'NT$';
    color: #009530;
}

.rates .rates-list .rate-descriptions .charge .amount.GBP::before {
    content: '£';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.CNY::before {
    content: '¥';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.KRW::before {
    content: '₩';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.THB::before {
    content: '฿';
    color: #B10043;
}
.rates .rates-list .rate-descriptions .charge .amount.PHP::before {
    content: '₱';
    color: #B10043;
}

.rates .rates-list .rate-descriptions .charge .amount.AUD::before {
    content: 'A$';
    color: #009530;
}