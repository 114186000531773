.welcome {
  background-image: url("/assets/images/backgrounds/welcome.png");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.welcome::-webkit-scrollbar {
  width: 0px !important;
}

.welcome .platforms {
  max-width: 1250px;
  margin: 0px auto;
}

.welcome .platforms .platform-card {
  transition: all 0.3s;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}

.welcome .platforms .platform-card.unavailable {
  /* Safari 6.0 - 9.0 */
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: not-allowed;
}

.welcome .platforms .platform-card:hover .platform-card-bottom .right-arrow-icon {
  margin-left: 5px;
}

.welcome .platforms .platform-card.unavailable .platform-card-bottom .title {
  color: #333333;
}

.welcome .platforms .platform-card.unavailable .platform-card-bottom .right-arrow-icon {
  display: none;
}

.welcome .platforms .platform-card .platform-card-top {
  position: relative;
  padding: 14px;
  min-height: 170px;
  background-color: #00000030;
  background-position: center center;
  background-size: cover;
}

.welcome .platforms .platform-card .platform-card-top::before {
  transition: all 0.3s;
  position: absolute;
  display: inline-block;
  content: "";
  height: 100%;
  width: 100%;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.welcome .platforms .platform-card:hover .platform-card-top::before {
  background-color: rgba(0, 0, 0, 0);
}

.welcome .platforms .platform-card.unavailable:hover .platform-card-top::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.welcome .platforms .platform-card .platform-card-top .tags {
  max-width: 57px;
  height: 24px;
  padding: 4px 10px;
  border-radius: 12px;
  position: relative;
  margin: 0px 6px;
}

.welcome .platforms .platform-card .platform-card-top .tags:last-child {
  margin-right: 0px;
}

.welcome .platforms .platform-card .platform-card-top .tags.badge::after {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  border-radius: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  right: -2px;
  background-color: #ffd100;
  border: 2px solid white;
}

.welcome .platforms .platform-card .platform-card-top .tags .tags-icon {
  height: 16px;
  width: 16px;
  background-color: #0f183159;
  border-radius: 3px;
  display: flex;
}

.welcome .platforms .platform-card .platform-card-top .tags.event {
  background-color: #2784d9;
  color: #ffffff;
}

.welcome .platforms .platform-card .platform-card-top .tags.shipment {
  background-color: #009530;
  color: #ffffff;
}

.welcome .platforms .platform-card .platform-card-top .tags.issue {
  background-color: #b10043;
  color: #ffffff;
}

.welcome .platforms .platform-card .platform-card-top .onboard .onboard-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.welcome .platforms .platform-card .platform-card-top .onboard .onboard-count {
  max-width: 170px;
  font-family: "IBMPlexSans-Medium";
  font-size: 12px;
  color: #ffffff;
}

.welcome .platforms .platform-card .platform-card-bottom {
  height: 37px;
  background-color: #2784d9;
}

.welcome .platforms .platform-card .platform-card-bottom .title {
  color: #ffffff;
  font-family: "IBMPlexSans-SemiBold";
  text-transform: uppercase;
  margin-left: 14px;
  line-height: 14px;
}

.welcome .platforms .platform-card .platform-card-bottom .right-arrow-icon {
  transition: all 0.3s;
}

.welcome .platforms .platform-card .notification-count {
  background-color: #50ACFF;
  height: 100%;
  width: 37px;
}

.welcome .custom-footer {
  padding-top: 35px;
}

.welcome .custom-footer .item-container {
  padding: 8px 25px;
  background-color: rgba(61, 205, 87, 0.7);
}

.welcome .custom-footer .item-container .powered-by {
  font-family: "IBMPlexSans";
  font-size: 14px;
  color: #ebebeb;
}

.welcome .custom-footer .bottom-border {
  height: 14px;
  background-color: #3dcd58;
}

.welcome {
  .side-bar {
    top: 0;

    .side-bar-body {
      height: 100%;
      max-height: 100%;
    }
  }

  .plant-search-box {
    margin-bottom: 1em;
  }

  .plant-selection-row {
    border-radius: 4px;
    background: #f5f5f5;
    padding: 1em;
    margin-bottom: 1em;
    cursor: pointer;

    &:hover {
      outline: 1px solid #3dcd57 !important;
      background: #3dcd571c;

      .plant-fab {
        background-color: #3dcd57;
      }

      .plant-check {
        color: #fff;
      }
    }

    .title {
      margin-bottom: 0.6em;
    }

    .sub-title {
      color: #b4b4b8;
    }

    .plant-fab {
      text-align: center;
      width: 35px;
      box-shadow: unset;
      height: 25px;
      top: 20%;
      background-color: #d6d6da;
      left: 20%;
    }

    .plant-check {
      color: #d6d6da;
      font-weight: 900;
    }
  }
}
