/* service-card-filter */
.filters .service-card-filter {
    background-color: #FFFFFF;
    border: 1px solid rgba(159, 160, 164, 0.2);
    border-radius: 4px;
    min-width: 95%;
}

.filters .clear-filter-options {
    min-width: 5%;
}

.filters .clear-filter-options .text {
    cursor: pointer;
    font-family: 'IBMPlexSans';
    font-size: 12px;
    color: #B10043;
}

.filters .service-card-filter .filter-options {
    padding: 0 12px 0 0;
    border-right: 2px solid rgba(159, 160, 164, 0.2);
}

.filters .service-card-filter .filter-options.last {
    border-right: 0px;
}

.filters .service-card-filter .filter-options .hidden-select {
    position: absolute;
    height: 100%;
    width: 90%;
    opacity: 0;
}

.filters .service-card-filter .filter-options .value {
    font-family: 'IBMPlexSans-Medium';
    font-size: 12px;
    color: #333333;
    max-width: 65px;
}

.filters .service-card-filter .filter-options .value.placeholder {
    font-family: 'IBMPlexSans';
    color: #9FA0A4;
}

.filters .service-card-filter .filter-options .value.full-width {
    max-width: unset;
}

.filters .service-card-filter .filter-options .clear-icon {
    cursor: pointer;
}

/* service-card-filter */