.benchmarks {
    padding: 20px;
}

.benchmarks .partner-days {
    align-items: center;
    display: flex;
    margin-left: 40px;
}

.benchmarks .partner-days::before {
    height: 20px;
    width: 20px;
}

.benchmarks .partner-days.cb::before {
    content: url('/assets/images/icons/rates/Doc-route-benchmark.png');
}

.benchmarks .partner-days.lp::before {
    content: url('/assets/images/icons/rates/Truck-route-benchmark.png');
}

.benchmarks .partner-days.fp::before {
    content: url('/assets/images/icons/rates/Ship-route-benchmark.png');
}