.label__table--dark{
    color: #333333;
    font-family: 'IBMPlexSans-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.label__table--light{
    color: #9FA0A4;
    font-family: 'IBMPlexSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}