.rc-steps-vertical .rc-steps-item-tail {
  padding: 0 0 0 0;
}

.rc-steps-vertical .rc-steps-item-tail:after {
  border: 1px dashed #e9e9e9;
}

.rc-steps-vertical .rc-steps-item-wait .rc-steps-item-tail:after,
.rc-steps-vertical .rc-steps-item-process .rc-steps-item-tail:after {
    background-color: #fff;
}

.rc-steps-vertical .rc-steps-item-tail {
    position: absolute;
    left: 1px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 0px 10px;
}

.rc-steps-vertical .rc-steps-item-container {
  padding-left: 30px;
  .rc-steps-item-content {
    margin-top: 0px;
  }
}

.rc-steps-vertical .shipment-list-item-progress-step .rc-steps-item-container .rc-steps-item-content {
  display: block;
  margin-top: 0px;
}

.rc-steps-horizontal .shipment-list-item-progress-step::before {
  display: inline-block;
  content: ' ';
  width: 100%;
  border-top: 1px dashed #e1e5ed;
  margin-top: 10px;
}

.rc-steps-vertical .rc-steps-item-description {
  padding-bottom: 22px;
}

.shipment-list {
  &-item {
    transition: all 0.3s;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(222, 224, 228, 0.63);
    margin-bottom: 20px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      border-color: #3dcd58;

      .shipment-list-item__sr-number {
        color: #3dcd58;
        border-bottom: 1px dashed #3dcd58;
      }
    }

    &__sr-number {
      display: inline-block;
      transition: all 0.3s;
      font-size: 20px;
      font-family: 'IBMPlexSans-Bold';
      text-transform: uppercase;
      line-height: 1.1;
      border-bottom: 1px dashed transparent;
    }

    &-card {
      padding: 20px;
      border: 1px solid #ebebeb;
      font-size: 12px;
      font-family: 'IBMPlexSans-Medium';

      .light-fonts {
        font-family: 'IBMPlexSans';
        color: #9fa0a4;
      }
    }

    .dg{
      color: #b10043;
      font-weight: 800;
      font-size: 12px;
    }

    .formd{
      font-weight: 800;
      font-size: 12px;
    }

    .light{
      color: #7f7f7f;
    }

    &-progress {
      overflow-x: scroll;
      &-step {
        margin-right: 0px !important;
        min-width: 200px;

       /* &::before {
          display: inline-block;
          content: ' ';
          width: 100%;
          border-top: 1px dashed #e1e5ed;
          margin-top: 10px;
        } */

        &:last-child {
          .rc-steps-item-container {
            padding-right: 0px;
          }

          &::before {
            border-top: none;
          }
        }

        .rc-steps-item-icon {
          position: absolute;
          background: none;
          border: none;
          top: 0px;
          left: 2px;
          height: 20px;
          width: auto;
        }

        .rc-steps-item-container {
          display: block;
          padding-right: 20px;

          .rc-steps-item-content {
            display: block;
            margin-top: 15px;

            .rc-steps-item-description {
              max-width: unset;
            }
          }
        }

        &.warehouse {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/warehouse/gray/svg/Consignee-Route-Gray@3x.svg');
          }
        }

        &.warehouse-current {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/warehouse/black/svg/Shipper-Route-Black@3x.svg');
          }


        }
        &.warehouse-complete {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/warehouse/green/svg/Shipper-Route-Green@3x.svg');
          }

          &::before {
            border-top: 1px dashed #3dcd58;
          }
        }

        &.warehouse-current.last-step::before {
          border-top: unset !important;
        }
        &.warehouse-complete.last-step::before {
          border-top: unset !important;
        }
        &.port {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/port/gray/svg/Port-Route-Gray@3x.svg');
          }
        }

        &.port-current {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/port/black/svg/Port-Route-Black@3x.svg');
          }

        }
        &.port-complete {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/port/green/svg/Port-Route-Green@3x.svg');
          }

          &::before {
            border-top: 1px dashed #3dcd58;
          }
        }

        &.container_yard {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/container-yard/gray/svg/Container-yard-black@3x.svg');
          }
        }

        &.container_yard-current {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/container-yard/black/Container-yard-black@3x.svg');
          }


        }
        &.container_yard-complete {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/container-yard/green/svg/Container-yard-green@3x.svg');
          }

          &::before {
            border-top: 1px dashed #3dcd58;
          }
        }

        &.storage {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/storage-location/gray/svg/Storage-location-black@3x.svg');
          }
        }

        &.storage-current {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/storage-location/black/svg/Storage-location-black@3x.svg');
          }

        }
        &.storage-complete {
          .rc-steps-item-icon {
            display: inline-block;
            content: url('/assets/images/icons/shipments-list/timeline/storage-location/green/Storage-location-Green@3x.svg');
          }

          &::before {
            border-top: 1px dashed #3dcd58;
          }
        }

        .step__description {
          font-size: 12px;
          font-family: 'IBMPlexSans-Medium';
          color: #333333;
          max-width: 250px;
        }
      }
    }

    &-current-event {
      font-size: 12px;
      font-family: 'IBMPlexSans-Medium';
      color: #7f7f7f;

      &.marker {
        margin-right: 8px;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        border: 4px solid rgba(61, 205, 88, 0.2);
        position: relative;

        &::before {
          position: absolute;
          top: 0px;
          left: 0px;
          content: ' ';
          display: inline-block;
          background-color: #3dcd58;
          height: 7px;
          width: 7px;
          border-radius: 100%;
        }
      }
    }

    .red-tag {
      background-color: #b10043;
      color: #fff;
    }

    .orange-tag {
      background-color: #e47f00;
      color: #fff;
    }
    .orange-tag-exception {
      background: rgba(228, 127, 0, 0.15000000596046448);
      color: #e47f00;
    }

    .green-tag {
      background-color: #3dcd58;
      color: #fff;
    }

    &-tag {
      border-radius: 2px;
      padding: 3px 7px !important;
      font-family: 'IBMPlexSans-SemiBold';
      font-size: 10px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #9fa0a4;

      &-empty {
        content: '&nbsp;';
        background-color: transparent;
        min-height: 20px;
      }

      &-primary {
        background-color: #9fa0a4;
      }

      &-danger {
        background-color: #9fa0a4;

        &_light {
          background-color: #c5627d;
          color: #9fa0a4;
        }
      }

      &-warning {
        background-color: '#E47F00';

        &_light {
          background-color: rgba(228, 127, 0, 0.15);
          color: #e47f00;
        }
      }

      &-success {
        background-color: '#3DCD58';

        &_light {
          background-color: rgba(61, 205, 88, 0.2);
          color: #3dcd58;
        }
      }
    }

    &-summery {
      margin-bottom: 10px;

      &__key {
        color: #9fa0a4;
        font-family: 'IBMPlexSans';
        font-size: 12px;
      }

      &__value {
        color: #333333;
        font-family: 'IBMPlexSans-Medium';
        font-size: 12px;
        cursor: pointer;
        border-bottom: 1px dashed #cdced1;

        &.no-underline {
          border-bottom: none;
          cursor: unset;
        }
      }
    }

    &-actions {
      padding-top: 10px;

      .btn {
        font-family: 'IBMPlexSans-SemiBold';
      }

      .btn_view {
        transition: all 0.3s;
        background-color: #f5f5f5 !important;
        color: #7f7f7f !important;
      }

      .btn_amend {
        background-color: rgba(61, 205, 88, 0.16) !important;
        color: #3dcd58 !important;
      }
    }
  }
}

.partners-sidebar-row {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 1em;
  margin-bottom: 1em;
  cursor: pointer;

  &:hover {
    outline: 1px solid #3dcd57 !important;
    background: #3dcd571c;
  }

  .title {
    margin-bottom: 0.6em;
  }
  .sub-title {
    color: #b4b4b8;
  }
}

.outbounds-sidebar-row {
  border-radius: 4px;
  background: #f5f5f5;
  padding: 1em;
  margin-bottom: 1em;

  .title {
    margin-bottom: 0.6em;
  }
  .sub-title {
    color: #b4b4b8;
  }
  .weight {
    float: right;
    margin: auto 2px;
  }

  .volume {
    float: right;
    margin: auto 2px;
  }

  .cs-count {
    float: right;
  }
}
