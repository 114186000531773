.bookings .section-left {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 20px;
}

.bookings .section-right {
  background-color: rgba(235, 235, 235, 0.5);
  border: 1px solid rgba(159, 160, 164, 0.2);
  border-radius: 4px;
  padding: 15px;
}

.container-step {
  padding-left: 25px;
  border-left: 1px dashed #adbfcd;
  position: relative;
}

.container-stepper {
  padding-left: 15px;
  margin-top: 20px;
}

.container-step-icon {
  position: absolute;
  left: -15px;
  top: 0px;
  height: 30px;
  width: 30px;
  background-color: #ececec;
  border-radius: 50%;
  text-align: center;
}

.container-step-header {
  height: 30px;
  display: flex;
  align-items: center;
}

.container-field-instruction {
  font-weight: lighter;
  font-size: 12px;
  color: #b8c2cc;
}

.container-theme-date-time-picker-field {
  display: flex;
  border-bottom: 1px solid #d6e0e6;
  position: relative;
  min-height: 26px;
  font-size: 14px;
}
