.page-loading-bar {
  background-color: #10B87B;
  height: 3px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
}

.page-header__title {
  font-family: 'IBMPlexSans-Bold';
  min-width: 110px;
  margin: 0px;
}

.drag_handle {
  color: rgba(159, 160, 164, 0.5);
  cursor: move;
}

.page-user-menu {
  min-width: 172px;
  margin: 0px;
  margin-top: 8px;
  padding: 0px;
  border: 1px solid rgba(159, 160, 164, 0.5);
  border-radius: 2px;
  box-shadow: 0 2px 7px 0 rgba(5, 13, 52, 0.1);
}
