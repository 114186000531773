.ql {
  &-editor {
    min-height: 200px;
  }
  &-picker {
    &-options {
      max-height: 200px;
      overflow: auto;
    }
  }   
}