/* cesium css */
.map-view .cesium-viewer-bottom,
.map-view .cesium-viewer-infoBoxContainer,
.map-view .cesium-viewer-animationContainer,
.map-view .cesium-viewer-fullscreenContainer,
.map-view .cesium-viewer-toolbar {
    display: none !important;
}

.map-view #cesiumContainer {
    width: 100%;
    height: calc(100vh - 102px);
}
