.side-bar {
  position: fixed;
  background-color: #62646935;
  height: 100%;
  width: 100%;
  z-index: 1001;
  top: 102px;
  left: 0px;
}

.side-bar .side-bar-dialog {
  width: 375px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -3px 2px 4px 0 rgba(0, 39, 69, 0.1);
  display: flex;
  flex-direction: column;
}

.side-bar .side-bar-dialog_sm {
  width: 720px;
  max-width: 100%;
}

.side-bar .side-bar-header {
  background-color: #000000;
  padding-left: 20px;
  color: #ffffff;
  font-family: "IBMPlexSans-Bold";
  font-size: 16px;
  height: 50px;
}

.side-bar-subheader {
  background-color: #c9c9c9;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: "IBMPlexSans-Bold";
  font-size: 14px;
  height: 40px;
  padding: 10px;
}

.side-bar .side-bar-header .header .close-icon {
  cursor: pointer;
}

.side-bar .side-bar-header .children {
  max-height: 41px;
}

.side-bar .side-bar-body {
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 192px);
  max-height: calc(100% - 192px);
  color: #333333;
}

.vessel-info-sidebar .side-bar-body {
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 192px);
  max-height: calc(100% - 192px);
  color: #333333;
}

.side-bar .side-bar-body.with-header-child {
  height: calc(100% - 243px);
}

.side-bar .side-bar-actions .btn {
  color: #ffffff;
}

/* Vessel Sidebar */
.vessel-info-sidebar .side-bar-dialog {
  width: 420px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -3px 2px 4px 0 rgba(0, 39, 69, 0.1);
}

.vessel-info-sidebar .sidebar-header {
  background-color: #333333;
  color: #fff;
  padding: 10px;
}

.vessel-info-sidebar .sidebar-header .f-10 {
  font-size: 10px;
}

.text-right {
  text-align: right;
}

.vessel-info-sidebar .sidebar-header .text-center {
  text-align: center;
}

.vessel-info-sidebar .side-bar-subheader {
  margin-bottom: unset;
}

.vessel-info-sidebar .side-bar-subheader .subheader-left {
  font-weight: 900;
  color: #4d4d4d;
}

.vessel-info-sidebar .side-bar-subheader .subheader-right {
  font-weight: 200;
  text-align: right;
  float: right;
  color: #7c7c7c;
}

.vessel-shipments-sidebar-row {
  background: #f5f5f5;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

.vessel-shipments-sidebar-row .light {
  font-size: 12px;
  color: #7c7c7c;
}

.vessel-shipments-sidebar-row .separator {
  padding-left: 5px;
  padding-right: 5px;
}

.vessel-shipments-sidebar-row .seperator::after {
  content: "•";
  color: #c2c2c2;
  margin: 0 10px;
  display: inline-block;
}

.vessel-shipments-sidebar-row .details-btn {
  color: #3dcd58;
  font-size: 12px;
  font-weight: 800;
  text-decoration: underline;
}

.height-unset {
  height: unset;
}

@media only screen and (max-width: 960px) {
  .side-bar {
    top: 54px;
  }

  .side-bar .side-bar-body {
    height: calc(100% - 145px);
    max-height: calc(100% - 145px);
  }
}
