.organizations {
    padding: 20px;
}

.organizations .organizations-header {
    margin-bottom: 20px;
}

.organizations .organizations-header .search-box {
    background-color: #FFFFFF;
    border: 1px solid rgba(159, 160, 164, 0.2);
    border-radius: 2px;
    padding: 10px;
}

.organizations .organizations-header .search-box .input {
    font-size: 12px;
}

.organizations .organizations-header .total {
    color: #9FA0A4;
    text-align: right;
}

.organizations .organizations-list .logo {
    height: 40px;
    padding: 5px;
}

.organizations .organizations-list .actions {
    height: 20px;
    width: auto;
    vertical-align: middle;
    cursor: pointer;
    z-index: 1;
}

.organizations .organizations-list .office {
    color: #9FA0A4;
    font-family: 'IBMPlexSans-SemiBold';
    margin: 0px 10px;
}

.organizations .organizations-list .office .inline-block{
    margin-bottom: 0px;
}

.organizations .logo-uploader {
    padding: 20px 12px;
    background-color: rgba(159, 160, 164, 0.1);
    border: 1px dashed rgba(159, 160, 164, 0.4);
    border-radius: 2px;
    width: 100%;
}

.organizations .logo-uploader .dz-message {
    margin: 0px;
}

.organizations .logo-uploader .dz-message * {
    font-family: 'IBMPlexSans';
    color: #333333;
}

.organizations .logo-uploader .dz-message .logo-area {
    width: 180px;
    height: 55px;
    margin: auto;
    margin-bottom: 16px;
    border-radius: 2px;
}

.organizations .logo-uploader .dz-message .logo-area .select-logo {
    height: inherit;
    width: inherit;
    background-color: #D9F0FA;
}

.organizations .logo-uploader .dz-message .logo-area .select-logo .icon {
    margin-right: 10px;
}

.organizations .logo-uploader .dz-message .logo-area .select-logo .text {
    color: #42B4E6;
    text-transform: uppercase;
    font-family: 'IBMPlexSans-SemiBold';
}

.organizations .logo-uploader .dz-message .title {
    font-family: 'IBMPlexSans-Bold';
    text-align: center;
}

.organizations .logo-uploader .dz-preview {
    height: 55px;
    min-height: auto;
    width: 180px;
    padding: 10px;
}

.organizations .logo-uploader .dz-preview .dz-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.organizations .logo-uploader .dz-preview .dz-image img {
    width: 100%;
    height: auto;
}

.organizations .logo-uploader .dz-message .note {
    font-size: 11px;
    color: #9FA0A4;
}

.organizations .organizations-details .office-list .light-fonts {
    color: #9FA0A4;
    font-family: 'IBMPlexSans';
}

.organizations .organizations-details .office-list .office-contact {
    display: inline-block;
    margin: 10px;
    position: relative;
}

.organizations .organizations-details .office-list .office-contact::after {
    display: block;
    content: ' ';
    position: absolute;
    background-color: rgba(159, 160, 164, 0.5);
    border-radius: 100%;
    width: 4px;
    height: 4px;
    top: 8px;
    left: -10px;
}

.organizations .organizations-details .office-list .office-contact:first-child {
    margin-left: 0px;
}

.organizations .organizations-details .office-list .office-contact:first-child::after {
    display: none;
}