*.regular {
  font-family: 'IBMPlexSans'
}

*.italic {
  font-style: italic;
}

*.medium {
  font-family: 'IBMPlexSans-Medium'
}

*.bold {
  font-family: 'IBMPlexSans-Bold'
}

*.semi-bold {
  font-family: 'IBMPlexSans-SemiBold'
}

.no-records {
  padding: 30px;
  color: #9FA0A4;
  text-align: center;
}

strong {
  font-family: 'IBMPlexSans-Medium'
}
