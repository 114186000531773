.login-form-border {
  border-left-color: #098a09c9;
  border-left-width: 55px;
  border-left-style: groove;
}

@media only screen and (max-width: 960px) {
  .login-form-border {
    border-left: none;
  }
}
