.custom-table {
    background-color: #FFFFFF;
    border: 1px solid rgba(159, 160, 164, 0.2);
    border-radius: 4px;
}

.custom-table .table-head .table-row .table-cell {
    color: #9FA0A4;
    font-family: 'IBMPlexSans-Medium';
    font-size: 14px;
    padding: 14px 25px;
    background-color: #FFFFFF;
    border: none;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom-table .table-body {
    color: #333333;
    font-family: 'IBMPlexSans';
}

.custom-table .table-body .table-row .no-records::after {
    content: "No Records!";
    font-size: 20px;
    font-family: 'IBMPlexSans-Medium';
    color: #9FA0A4;
    text-align: center;
    padding: 20px;
}

.custom-table .table-body .table-row {
    border-bottom: 2px solid rgba(159, 160, 164, 0.2);
    font-family: inherit;
}

.custom-table .table-body .table-row:last-child {
    padding: 14px 25px;
    border-bottom: none;
}

.custom-table .table-body .table-row .table-cell {
    font-size: 14px;
    padding: 14px 25px;
    font-family: inherit;
    border: none;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom-table .table-body .table-row .actions {
    opacity: 0;
    transition: 0.3s all;
}

.custom-table .table-body .table-row:hover {
    background-color: #F4FCF5;
}

.custom-table .table-body .table-row:hover .actions {
    opacity: 1;
}
