:root {
  --fg-primary: #333333;
  --fg-green: #3dcd58;
  --fg-gray: #9FA0A4;
  --fg-orange: #FFAA3E;
  --fg-red: #B10043;
  --fg-yellow: #FFD100;

  --bg-green: #3dcd58;
  --bg-light-orange: #FFF6EB;
  --bg-light-green: #F5F5F5;
  --bg-transparent-green: #ECFAEE;
  --bg-orange: #FFAA3E;
  --bg-gray: #FAFAFA;
  --bg-white: #FFFFFF;
  --bg-light-red: #b1004312;
  --bg-light-blue: #42b4e61a;
}

/* Foreground colors */
.fg-primary {
  color: var(--fg-primary);
}

.fg-green {
  color: var(--fg-green);
}

.fg-gray {
  color: #9FA0A4;
}

.fg-dark-gray {
  color: #626469;
}

.fg-blue {
  color: #42B4E6;
}

.fg-orange {
  color: var(--fg-orange);
}

.fg-red {
  color: var(--fg-red);
}

.fg-yellow {
  color: var(--fg-yellow);
}

/* Background colors */
.bg-green {
  background-color: var(--bg-green);
}

.bg-orange {
  background-color: var(--bg-orange);
}

.bg-light-orange {
  background-color: var(--bg-light-orange);
}

.bg-light-green {
  background-color: var(--bg-light-green);
}

.bg-transparent-green {
  background-color: var(--bg-transparent-green);
}

.bg-gray {
  background-color: var(--bg-gray);
}

.bg-white {
  background-color: var(--bg-white);
}

.bg-transparent-green {
  background-color: var(--bg-transparent-green);
}

.bg-light-red {
  background-color: var(--bg-light-red);
}

.bg-light-blue {
  background-color: var(--bg-light-blue);
}

/* Border colors */
.border-gray {
  border-color: #E1E5ED;
}

.border-green {
  border-color: #3dcd58;
}

.border-blue {
  border-color: #42B4E6;
}

.border-transparent {
  border-color: transparent;
}
