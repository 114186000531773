.shipment-document .shipment-document__item {
  background-color: #fafafd;
  border-radius: 5px;
  padding: 20px;
}

.shipment-document .shipment-document__item .document__label {
  text-transform: uppercase;
  color: #4d5477;
  font-family: "ibm_plex_sans_semiboldregular";
}

.shipment__tag {
  font-family: "ibm_plex_sans_semiboldregular";
  font-size: 12px;
  font-weight: 500;
  color: #424d62;
}

.shipment__title {
  font-family: "ibm_plex_sans_semiboldregular";
  color: #424d62;
  font-size: 22px;
}

.shipment__subtitle {
  font-family: "airbnb_cereal_appbook";
  color: #9fa0a4;
  font-size: 14px;
}

.shipment__subtitle_dark {
  color: #424d62;
  font-weight: 500;
  font-family: "ibm_plex_sans_semiboldregular";
}

.shipment__item-detail {
  position: relative;
}

.shipment .rc-steps-dot .rc-steps-item-icon {
  position: absolute;
  top: -3px;
}
.shipment__item-detail:before {
  content: "";
  height: 100%;
  width: 0;
  border: 1px solid rgba(222, 224, 228, 0.6299999952316284);
  position: absolute;
  top: 0;
  margin-left: -20px;
}

.rc-steps-item-finish .rc-steps-item-tail:after,
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot,
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot,
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #18ab76 !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #18ab76 !important;
}

.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #18ab76 !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: #18ab76 !important;
}
.shipment__item-route,
.shipment__item-detail {
  height: 240px;
}

.shipment-list-item-tag {
  margin-right: 5px;

  &:last-child {
    margin-right: 0px;
  }
}

.shipment .rc-steps-item-finish .rc-steps-item-icon {
  border-color: transparent !important;
}

.shipment .rc-steps-icon img {
  width: 9px;
  height: 9px;
}

.shipment .rc-steps-item-finish .rc-steps-icon,
.shipment .rc-steps-item-process .rc-steps-icon,
.shipment .rc-steps-item-wait .rc-steps-icon {
  display: none;
}

.shipment .rc-steps-item-process .rc-steps-item-icon:after,
.shipment .rc-steps-item-finish .rc-steps-item-icon:after {
  border-color: #18ab76 !important;
  width: 9px !important;
  height: 9px !important;
  position: relative;
  content: url("/assets/images/icons/oval.svg") !important;
}

.shipment .rc-steps-item-wait .rc-steps-item-icon:after {
  width: 9px !important;
  height: 9px !important;
  position: relative;
  content: url("/assets/images/icons/wait.svg") !important;
}

.shipment .rc-steps-item-process .rc-steps-item-icon,
.rc-steps-item-wait .rc-steps-item-icon {
  border-color: transparent !important;
  background: transparent !important;
}

.rc-steps-item-title {
  width: 149px;
  position: absolute !important;
  top: -25px;
  left: 0;
}

.rc-steps-item-description,
.rc-steps-item-title {
  font-size: 10px !important;
  font-family: "airbnb_cereal_appbook" !important;
  font-weight: 300 !important;
  color: #9fa0a4 !important;
}

.rc-steps-small .rc-steps-item-icon {
  width: 9px !important;
  height: 9px !important;
}

/* START Manage Page */
.manage-btn__next {
  background-color: #10b87b !important;
  min-width: 160px;
  height: 47px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "ibm_plex_sans_mediumregular";
  text-align: center;
  color: #ffffff !important;
}

.manage-page .list-container {
  max-height: calc(100vh - 260px);
  overflow-y: auto;
}

.manage-page .booking__item:first-child {
  margin-top: 0px;
}

.manage-page .booking__item:last-child {
  margin-bottom: 0px;
}

.manage-page .btn__action,
.booking-details .btn__action {
  background-color: rgba(39, 132, 217, 0.12) !important;
  color: #2784d9 !important;
  font-family: "ibm_plex_sans_semiboldregular";
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  min-width: 130px;
  border-radius: 3px;
  padding: 15px;
  line-height: 1;
}

.manage-page .btn__action.btn__action_existing {
  background-color: rgba(255, 170, 62, 0.14) !important;
  color: #ffaa3e !important;
}

.manage-page .btn__action.btn__action_remove {
  background-color: rgba(255, 68, 62, 0.14) !important;
  color: #ff4b3e !important;
}

.manage-page .btn__action.btn__action_import {
  font-family: "ibm_plex_sansregular";
  background-color: rgba(16, 184, 123, 0.1) !important;
  color: #10b87b !important;
  padding: 13px;
  line-height: 1.4;
  display: flex;
  justify-content: space-around;
}

.manage-page .btn__action.btn__action_wide,
.booking-details .btn__action.btn__action_wide {
  padding-left: 25px;
  padding-right: 25px;
}

.manage-page .btn__action.btn__action_update {
  background-color: rgba(16, 184, 123, 0.1) !important;
  color: #10b87b !important;
}

.manage-page .btn__action.btn__action_import .import__icon {
  text-align: left;
}

.manage-page .btn__action:disabled,
.manage-btn__next:disabled {
  background-color: #e1e5ed !important;
  color: #adbfcd !important;
}

/* END Manage Page */

/* Booking */
.booking__item {
  border-radius: 5px;
  background: white;
  margin-top: 10px;
}

.booking__item.border-left {
  border-left: 3px solid #2784d9 !important;
}

.booking__item.border-left.border-left_green {
  border-left: 3px solid #10b87b !important;
}

.booking__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #424d62;
  padding: 8px 0 12px 8px;
  border-bottom: 1px dashed #e1e5ed;
}

.booking__ob_grouped {
  border-bottom: 1px dashed #cecece;
}

.booking-delete-button {
  color: red;
  opacity: 0.5;
  line-height: 1;
}

.booking-delete-button:hover {
  opacity: 0.9;
}

.booking__no {
  color: #424d62;
  font-family: "ibm_plex_sans_semiboldregular";
  font-size: 20px;
  font-weight: 700;
}

.booking__route {
  color: #adbfcd;
  font-family: "ibm_plex_sansregular";
  font-size: 14px;
  font-weight: 500;
}

.booking__details {
  padding: 15px;
}

.booking__details__goodsDate {
  color: #424d62;
  font-size: 14px;
  margin-right: 15px;
  font-weight: lighter;
  vertical-align: middle;
}

.outboundCard-footer {
  background-color: #e1e5ed;
  padding: 8px 15px;
  border-radius: 0 0 5px 5px;
}

.outbound__count {
  color: #adbfcd;
  min-width: 0px;
  font-size: 14px;
  border-bottom: 1px dashed;
  padding: 0px;
  background-color: transparent !important;
  border-radius: 0px;
  text-transform: none;
  font-weight: normal;
  line-height: 18px;
}

.booking__label {
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
}

.booking__label_header {
  color: #9fa0a4;
  font-weight: 400;
  margin-bottom: 5px;
}

.booking__label_value {
  color: #006887;
  font-weight: 600;
}

.booking__label_black {
  color: #424d62;
}

.btn-details {
  background-color: rgba(225, 229, 237, 0.5);
  border-radius: 3px;
  width: 130px;
  height: 43px;
  color: #9ca7c4;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
}

.btn-review {
  background-color: rgba(39, 132, 217, 0.1);
  border-radius: 3px;
  width: 130px;
  height: 43px;
  color: #2784d9;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
}

.bg-trames_lightestgrey {
  background-color: rgba(222, 224, 228, 0.3);
}

.booked__item {
  border-right: 1px dashed #e1e5ed;
  opacity: 0.6000000238418579;
}

.booked__header {
  color: #9fa0a4;
  font-family: "ibm_plex_sansregular";
  font-size: 16px;
  font-weight: 600;
}

.booked__count {
  color: #9ca7c4;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 400;
}

.booked__date {
  color: #9ca7c4;
  font-family: "ibm_plex_sans_mediumitalic";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.book__header {
  padding: 14px;
  cursor: pointer;
}

.book__no {
  color: #424d62;
  font-family: "ibm_plex_sansregular";
  font-size: 16px;
  font-weight: 700;
}

.book__route {
  color: #e1e5ed;
  font-family: "ibm_plex_sansregular";
  font-size: 14px;
  font-weight: 500;
}

.book__instruction {
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 400;

  padding-bottom: 10px;
}

.book__sizelabel {
  color: #9ca7c4;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
}

.book__instruction_waiting {
  color: #9fa0a4;
}

.book__instruction_normal {
  color: #9fa0a4;
}

.book__instruction_orange {
  color: #ffaa3e;
  font-weight: 500;
}

.book__details {
  padding-top: 15px;
  color: #9fa0a4;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 400;
}

.book__size {
  border: 1px solid #e1e5ed;
  border-radius: 3px;
  width: 198px;
  height: 37px;
}

.book-border_bottom {
  border-bottom: 1px solid #e1e5ed;
}

.group-label {
  text-transform: uppercase;
  color: #006887;
  font-family: "ibm_plex_sans_mediumregular";
  font-size: 14px;
  font-weight: 500;
}

.import-count {
  color: #adbfcd;
  font-family: "ibm_plex_sansregular";
  font-size: 12px;
  font-weight: 400;
}

.active-header {
  background-color: #424d62;
  border-radius: 3px 0 0 3px;
}

.label-box {
  color: #ffffff;
  font-family: "ibm_plex_sans_mediumregular";
  font-size: 18px;
  font-weight: 500;
  padding: 11px;
}

.disable-header {
  background-color: rgba(66, 77, 98, 0.4);
  border-radius: 0 3px 3px 0;
}

.label-box__first {
  position: relative;
}

.label-box__first:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -29px;
  border-top: 21px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 30px solid #424d62;
}

.label-box__first_grey:after {
  z-index: 999;
  right: -30px;
  border-left: 30px solid #b3b8c0;
}

.header__circle {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  width: 23px;
  height: 23px;
  color: #ffffff;
  font-family: "ibm_plex_sans_semiboldregular";
  font-size: 14px;
  font-weight: 700;
}

.header__number {
  left: 8px;
  top: 0px;
}

.tag__yellow {
  background-color: rgba(255, 170, 62, 0.12);
  border-radius: 12.5px;
  padding: 5px 12px;
  line-height: 1;
  height: 25px;
  color: #ffaa3e;
  font-family: "ibm_plex_sans_mediumregular";
  font-size: 12px;
  font-weight: 500;
}

.tag-msg__yellow {
  color: #ffaa3e;
  font-family: "ibm_plex_sans_mediumitalic";
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.label-box__second {
  z-index: 1;
  width: 175px;
  padding-left: 40px;
}

.country-filter {
  background: none;
}

.route__tag {
  height: 45px;
  border-right: 1px solid #e1e5ed;
}

.route__tag_toggle {
  position: relative;
  width: 290px;
}

.route .country select {
  width: 240px;
  position: absolute;
  left: 5px;
}

.border-dashed_bottom {
  border-bottom: 1px dashed #e1e5ed;
}

.group__item {
  padding: 20px;
}

.group__item_white {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(222, 224, 228, 0.63);
  padding-bottom: 0;
}

.step-bg {
  background-color: rgba(242, 249, 245, 0.45);
  padding: 20px;
}

.booking__item_first {
  background-color: #f7f8fa;
  box-shadow: 3px 0 4px 0 rgba(222, 224, 228, 0.63);
}

.booking__item_second {
  background-color: #e6eaec;
  box-shadow: 2px 2px 4px 0 rgba(222, 224, 228, 0.63);
}

.booking__label_bold {
  color: #9ca7c4;
  font-family: "ibm_plex_sans_semiboldregular";
  font-size: 16px;
  font-weight: 700;
}

.btn-disabled {
  background-color: #e1e5ed;
  border-radius: 3px;

  color: #adbfcd;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
}

.btn-booking {
  width: 130px;
  height: 43px;
}

.booking__confirmed_row {
  background: #f9f9f9;
}

.booking-proposed__header {
  color: #3dce57;
  cursor: pointer;

  &:hover {
    text-decoration: underline dashed;
    color: #3dce57;
  }
}

.shipment-list-tag {
  border-radius: 2px;
  padding: 3px 7px;
  font-family: "IBMPlexSans-SemiBold";
  font-size: 10px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #9fa0a4;
  height: 20px;
  cursor: pointer;
}
.red-tag {
  background-color: #b10043;
  color: #fff;
}

.orange-tag {
  background-color: #e47f00;
  color: #fff;
}
.orange-tag-exception {
  background: rgba(228, 127, 0, 0.15000000596046448);
  color: #e47f00;
}

.green-tag {
  background-color: #3dcd58;
  color: #fff;
}
